import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerPaymentDetailService extends AbstractService {
    private controller = "customer-payment-details";
    
    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(customerPaymentDetail: any) {
        const d = { customerPaymentDetail };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public submit(customerPaymentId: number, customerOrderIds: number[]) {
        const d = { customerPaymentId, customerOrderIds };
        const url = this.makeUrl(`${this.controller}/submit`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public delete(customerPaymentDetail: any) {
        const d = { customerPaymentDetail };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}